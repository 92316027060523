/**
 * 企业预登记 和 中间商推荐 项目 相关接口
 */

 //@ts-ignore
 import { $get, $post, $dataPost, $put } from "../../../http/axios";
 export enum ExactAlternativeProjectType {
     /** @desc 中间商推荐 */
    INTERMEDIARIES = '1',
    /** @desc 企业预登记 */
    PRE_REGISTRATION = '2'
    
 }
/**
 * 预登记项目和中介商项目共用接口
 * @param {PreRegistrationProject} form 
 * @param {ExactAlternativeProjectType} type 
 * @returns 
 */
 export async function saveExactAlternativeEnterpriseLibraryForEn(form:PreRegistrationProject,type:ExactAlternativeProjectType=ExactAlternativeProjectType.PRE_REGISTRATION):Promise<API.BaseResponse<any>>{
    let data =  await  $post(`/exactalternativeenterpriselibrary/editExactAlternativeEnterpriseLibraryForEn`,{},{...form,type})
    return data.result
}

/**
 * 保存预登记项目
 * @param  {PreRegistrationProject}  form 
 * @returns 
 */
export  const savePreRegistrationProject = (form:PreRegistrationProject)=> saveExactAlternativeEnterpriseLibraryForEn(form,ExactAlternativeProjectType.PRE_REGISTRATION)

/**
 * 保存中介商项目
 * @param  {PreRegistrationProject}  form 
 * @returns 
 */
export  const saveIntermediariesProject = (form:PreRegistrationProject)=> saveExactAlternativeEnterpriseLibraryForEn(form,ExactAlternativeProjectType.INTERMEDIARIES)

/**
 * 获取产业类型列表 （用于下拉框选择）
 * @returns 
 */
export const getIndustryTypeList:()=>Promise<IndustryTypeItem[]> = async ()=>{
    const data = await $post(`/dict/getDictBListByKeyAndSelectType?dictKey=industry_category&selectType=1`);
    return data.result
}
/**
 * 根据id获取项目详情
 * @param id 
 * @returns 
 */
export async function getExactAlternativeEnterpriseLibraryById(id:string |number):Promise<PreRegistrationProject> {
    const {result} = await $post('/exactalternativeenterpriselibrary/getExactAlternativeEnterpriseLibraryById', {id})
    return result
}